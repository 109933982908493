import { Module } from "./enums"

export const INPUT_TYPES = ["text", "textarea", "search", "number", "select"]

export const FIRST_ROUND_MODULES = [
  Module.CATEGORIES,
  Module.QUESTIONS,
  Module.ANSWERS,
  Module.FINALE,
  Module.RESULTS,
  Module.WRAPUP
]

export const NTH_ROUND_MODULES = [
  Module.CATEGORIES,
  Module.QUESTIONS,
  Module.ANSWERS,
  Module.FINALE,
  Module.RESULTS,
  Module.WRAPUP
]

export const TIME_TO_ANSWER = 1000 * 10

export const BUZZ_BUTTON_DELAY = 1000 * 10

export const MAX_CHALLENGES = 2

export const MIN_SCORE = -500

import { computed } from "@vue/composition-api"

import useStore from "@/use/useStore"

import type { User } from "../types"

export default function useUsers() {
  const { store } = useStore()

  const users = computed<User[]>(() => store.getters.onlineUsersArray)

  return { users }
}

import { computed } from "@vue/composition-api"

import useStore from "@/use/useStore"

export default function useViewer() {
  const { store } = useStore()

  const viewer = computed(() => store.state.auth.user)

  return { viewer }
}

import { computed } from "@vue/composition-api"

import { FIRST_ROUND_MODULES, NTH_ROUND_MODULES } from "../constants"

import useJeopardAiRound from "./useJeopardAiRound"

export default function useJeopardAiModules() {
  const { round } = useJeopardAiRound()
  const modules = computed(() => {
    if (round.value === 1) return FIRST_ROUND_MODULES

    return NTH_ROUND_MODULES
  })
  return { modules }
}

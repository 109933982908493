import { computed, watch } from "@vue/composition-api"
import { KEY_SPACE, KEY_LEFT, KEY_RIGHT } from "keycode-js"
import { runTransaction, serverTimestamp, update } from "firebase/database"
import { chain } from "lodash"

import { Mode } from "@shared/enums"
import { useConfirm } from "@/use"
import useStore from "@/use/useStore"
import useTeams from "@/use/useTeams"
import useUsers from "./useUsers"
import useModeRef from "./useModeRef"
import useTeamsRef from "./useTeamsRef"
import useUsersRef from "./useUsersRef"
import useJeopardAiState from "./useJeopardAiState"
import useJeopardAiRound from "./useJeopardAiRound"
import useCurrentTeamIdRef from "./useCurrentTeamIdRef"
import useJeopardAiRootRef from "./useJeopardAiRootRef"
import useJeopardAiRoundRef from "./useJeopardAiRoundRef"
import useJeopardAiStateRef from "./useJeopardAiStateRef"
import useJeopardAiAnswers from "./useJeopardAiAnswers"
import useJeopardAiModules from "./useJeopardAiModules"
import useViewer from "./useViewer"
import useJeopardAiNavigation from "./useJeopardAiNavigation"

import { Module, AnswersMode } from "../enums"
import { JeopardAiUser, Question } from "../types"
import { INPUT_TYPES } from "../constants"

import UserClass from "@shared/User"

export default function useJeopardAiMain() {
  const { state } = useJeopardAiState()
  const { store } = useStore()
  const { round } = useJeopardAiRound()
  const { ref: roundRef } = useJeopardAiRoundRef()
  const { ref: stateRef } = useJeopardAiStateRef(round)
  const { ref: rootRef } = useJeopardAiRootRef(round)
  const { ref: modeRef } = useModeRef()
  const { viewer } = useViewer()
  const { users } = useUsers()
  const { next: advance } = useJeopardAiNavigation()
  const { ref: currentTeamIdRef } = useCurrentTeamIdRef()
  const { ref: teamsRef } = useTeamsRef()
  const { ref: usersRef } = useUsersRef()
  const { answers } = useJeopardAiAnswers()
  const { teams } = useTeams()
  const { confirm } = useConfirm()
  const { modules } = useJeopardAiModules()

  const module = computed(() => state.value?.module ?? Module.CATEGORIES)
  const working = computed(
    () =>
      state.value?.working === true ||
      (state.value?.module === Module.QUESTIONS &&
        state.value?.questions == null)
  )
  const category = computed(() => state.value?.category)
  const finale = computed(() => {
    if (state.value?.finale?.id == null) return undefined

    return state.value.finale
  })
  const questions = computed<Question[]>(() =>
    Object.values(state.value?.questions ?? {})
  )

  watch(
    module,
    value => {
      store.commit("JeopardAi/UPDATE_MODULE", value)
    },
    { immediate: true }
  )

  const question = computed(() =>
    questions.value.find(question => question.id === questionId.value)
  )

  const questionId = computed<string | undefined>(() => state.value?.questionId)

  async function next() {
    const index = modules.value.findIndex(m => module.value === m) ?? 0
    const value = modules.value[index + 1]
    if (value == null) return

    await update(rootRef.value, {
      [`state/module`]: value,
      [`state/questionId`]: null,
      [`state/flipped`]: null,
      [`state/timestamp`]: serverTimestamp()
    })

    if (value === Module.RESULTS) {
      if (store.getters.getCurrentMission?.activityId != null) advance()
      // Legacy RTDB ref
      await teamsRef.value.update(
        Object.keys(teams.value ?? {}).reduce((acc, teamId) => {
          acc[`${teamId}/totalScore`] = answers.value.reduce((acc, answer) => {
            if (answer.teamId !== teamId) return acc
            return acc + answer.amount
          }, 0)
          return acc
        }, {})
      )
      // Legacy RTDB ref
      await currentTeamIdRef.value.set(null)
      await modeRef.value.set(Mode.Over)
    }
  }

  async function prev() {
    const index = modules.value.findIndex(m => module.value === m) ?? 0
    let value = modules.value[index - 1]

    if (value == null) {
      if (round.value == null || round.value <= 1) return
      const confirmed = await confirm({
        message: `Would you like to go to the previous round?`,
        title: "System"
      })
      if (confirmed === false) return
      runTransaction(roundRef.value, value => {
        if (value == null) return
        if (value <= 1) return
        return value - 1
      })
      return
    }

    if (state.value?.module === Module.FINALE) value = Module.QUESTIONS

    if (value === Module.QUESTIONS && users.value.some(UserClass.isScribe)) {
      await usersRef.value.update(
        users.value.reduce((acc, val) => {
          acc[`${val.id}/selected`] = false
          return acc
        }, {})
      )
    }

    update(stateRef.value, {
      working: false,
      module: value,
      questionId: null,
      timestamp: serverTimestamp()
    })
  }

  async function handleKeyPress(event) {
    if (INPUT_TYPES.includes(event.target?.type)) return

    if (event.keyCode === KEY_SPACE || event.keyCode === KEY_RIGHT) {
      if (state.value?.module === Module.QUESTIONS) {
        return
      }

      if (state.value?.module === Module.FINALE) {
        const users = store.state.JeopardAi.users as JeopardAiUser[]
        const filtered = answers.value.filter(
          answer => answer.questionId === state.value?.finale?.id
        )
        if (users.length > 0) {
          const unscored = users.filter(user =>
            filtered.every(answer => answer.userId !== user.id)
          )
          if (unscored.length > 0) {
            const message =
              unscored.length > 1
                ? `Would you like to proceed without scoring players?`
                : `Would you like to proceed without scoring a player?`
            const confirmed = await confirm({
              message,
              title: "System"
            })
            if (confirmed === false) return
          }
        }
      }

      if (
        state.value?.module === Module.ANSWERS &&
        event.keyCode === KEY_SPACE
      ) {
        prev()
        return
      }

      next()
      return
    }

    if (event.keyCode === KEY_LEFT) {
      prev()
      return
    }
  }

  function setCategory(value: string) {
    update(stateRef.value, {
      questionId: null,
      category: value
    })
  }

  const MAX_DRAG_INDEX = 5
  const DRAG_DELAY = 500

  const drag = computed<number>(() => {
    const values = chain(answers.value)
      .orderBy("timestamp", "asc")
      .uniqBy("questionId")
      .reverse()
      .value()

    let i = 0

    for (; i < Math.min(values.length, MAX_DRAG_INDEX); i++) {
      if (values[i].teamId !== viewer.value?.teamID) break
    }

    return i * DRAG_DELAY
  })

  const scribes = computed(() => {
    const module = state.value?.module
    const a = module === Module.QUESTIONS ? 1 : 0
    const version = state.value?.version ?? 0
    const n = version + a
    const values = chain(users.value)
      .filter(UserClass.isSelectable)
      .sortBy("id")
      .groupBy("teamID")
      .map(
        value =>
          value.find(UserClass.isScribe) ?? value[n % value.length] ?? value[0]
      )
      .value()

    if (module === Module.QUESTIONS) {
      if (users.value.some(UserClass.isScribe))
        return users.value.filter(UserClass.isScribe)
      const sorted = [...answers.value].sort(
        (a, b) => b.timestamp - a.timestamp
      )
      const teamId = sorted.find(({ correct }) => correct)?.teamId
      return values.filter(user => user.teamID === teamId)
    }

    return values
  })

  watch(
    scribes,
    value => {
      store.commit("JeopardAi/UPDATE_SCRIBES", value)
    },
    { immediate: true }
  )

  return {
    prev,
    round,
    drag,
    handleKeyPress,
    AnswersMode,
    finale,
    setCategory,
    question,
    working,
    questionId,
    questions,
    module,
    Module,
    state,
    category
  }
}

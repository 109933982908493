import { computed } from "@vue/composition-api"
import { db } from "@/firebase"

import useStore from "@/use/useStore"

export default function useTeamsRef() {
  const { store } = useStore()
  const orgId = computed<string>(() => store.getters.orgID)
  const gameId = computed<string>(() => store.getters.gameID)

  const ref = computed(() =>
    db.auxiliary().ref(`org/${orgId.value}/game/${gameId.value}/teams`)
  )

  return { ref }
}

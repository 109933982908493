import MissionCollection from "@shared/MissionCollection"
import NavigationService from "@/services/navigation.service"
import useStore from "@/use/useStore"
import useIdentities from "@/use/useIdentities"

export default function useJeopardAiNavigation() {
  const { store } = useStore()
  const { orgID, gameID, missionID } = useIdentities()

  async function next() {
    const missions = MissionCollection.normalize(store.getters.missions)
    const index = missions.findIndex(mission => mission.id === missionID.value)
    const missionId = missions[index + 1]?.id
    if (missionId == null) return
    await NavigationService.navigateTo(orgID.value, gameID.value, missionId)
  }

  return { next }
}

import { computed } from "@vue/composition-api"
import { db } from "@/firebase"

import useStore from "@/use/useStore"

export default function useCurrentTeamIdRef() {
  const { store } = useStore()
  const orgId = computed<string>(() => store.getters.orgID)
  const roomId = computed<string>(() => store.getters.gameID)

  const ref = computed(() =>
    db
      .auxiliary()
      .ref(`org/${orgId.value}/game/${roomId.value}/gameStatus/flippedTeamID`)
  )

  return { ref }
}
